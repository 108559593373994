/* App.css */
body {
  background-color: #F9F9F9 !important;
  color: #000000;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  /* For mobile, stack the videos on top of each other */
  .d-flex.flex-wrap {
    flex-direction: column;
  }

  /* Adjust the size of pills/tabs for mobile */
  .nav-pills-mobile {
    font-size: 12px; /* You can adjust the font size as needed */
    padding: 10px; /* You can adjust the padding as needed */
  }
}

.logo {
  display:flex;
  justify-content:center; 
  align-items:center;  
  padding-right: 80px;  
}

.player-container,
.convert-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.title {
  font-weight: 700;
  justify-content: 'center';
  align-items: 'center';
  color: #ff0022;
  background: -webkit-linear-gradient(45deg, #082944, 67%, #ff0e2e);
  background: linear-gradient(45deg, #082944, 67%, #ff0e2e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-wrapper {
  width: 100%;
  max-width: 640px;
  font-size: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  color: #ff0022;
  font-weight: 20;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.video-player {
  width: 100%;
}

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}

.file-input {
  padding: 10px;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  width: 500px;
}

.upload-button {
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: #09118a;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.blur-backdrop {
  border-radius:30;
  background-color: rgba(128,128,128, 0.4);
  box-shadow: 2px 2px 4px 4px grey;
  backdrop-filter:blur(50px)  !important
}

.download-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: #09118a;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}